<script setup lang="ts">
import { generateId } from '../util/generateId';
import { useNavStore } from '../store/nav';

const props = defineProps({
  emitInputChange: {
    type: String,
    default: undefined,
  },
  emitSearchClick: {
    type: String,
    default: undefined,
  },
  emitEnter: {
    type: String,
    default: undefined,
  },
  emitFocus: {
    type: String,
    default: undefined,
  },
  emitBlur: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
    required: false,
  },
  labelOn: {
    type: Object as any,
    default: () => {
      return {} as any;
    },
  },
  labelAttrs: {
    type: Object as any,
    default: () => {
      return {} as any;
    },
  },
  inputAttrs: {
    type: Object as any,
    default: () => {
      return {} as any;
    },
  },
});
const plcHldr = props.placeholder || useTranslate('Search.search');
const emit = defineEmits<{ (event: string, value: any): void }>();
const state = reactive({
  searchValue: '',
});
const navStore = useNavStore();
const compId = computed(() => generateId('si'));
const hasValue = (): boolean => {
  return state.searchValue !== '';
};
const enterOnSearch = (e: KeyboardEvent) => {
  if ((e.code === 'Enter' || e.key === 'Enter') && props.emitEnter) {
    emit(props.emitEnter, state.searchValue);
  }
};
const searchInputChange = () => {
  if (props.emitInputChange) emit(props.emitInputChange, state.searchValue);
};
const searchInputFocus = (e: any) => {
  navStore.setSubnavOpenIndex(null);
  if (props.emitFocus) emit(props.emitFocus, e);
};
const searchInputBlur = (e: any) => {
  if (props.emitBlur) emit(props.emitBlur, e);
};
</script>

<template>
  <label
    :for="compId + '-search-input-bar'"
    :class="['search-input-label', { 'search-input-filled': hasValue() }]"
    v-bind="props.labelAttrs"
  >
    <div class="search-svg-container">
      <SearchLine />
    </div>
    <input
      v-bind="props.inputAttrs"
      :id="compId + '-search-input-bar'"
      v-model="state.searchValue"
      :name="props.name"
      :placeholder="plcHldr"
      class="search-input"
      type="search"
      autocomplete="off"
      @input="searchInputChange"
      @keydown.enter="enterOnSearch"
      @focus="searchInputFocus"
      @blur="searchInputBlur"
    />
    <slot />
  </label>
</template>

<style lang="scss" scoped>
.search-input-label {
  align-items: center;
  background-color: $color-neutral-cool-50;
  border-radius: 4px;
  box-sizing: border-box;
  fill: $color-neutral-cool-700;
  display: flex;
  padding: 1rem;
  transition: background-color 0.25s, color 0.25s, fill 0.25s;
  &:hover,
  &.search-input-filled {
    fill: $color-neutral-cool-900;
    background-color: $color-neutral-cool-100;
  }
  &.search-input-filled:not(:focus-within) {
    &:hover {
      background-color: $color-neutral-cool-200;
    }
  }
  &:focus-within {
    background-color: $color-secondary-50;
    fill: $color-secondary-500;
    .search-button,
    .clear-svg {
      color: $color-secondary-500;
      border-radius: 4px;
      fill: $color-secondary-500;
      &:hover,
      &:focus {
        color: $color-secondary-600;
        fill: $color-secondary-600;
      }
      &:focus {
        outline: 2px solid $color-secondary-600;
        outline-offset: 2px;
      }
    }
  }
  &:not(:focus-within, .search-input-filled) {
    .clear-svg,
    button {
      display: none;
    }
  }
}
.search-button,
.clear-svg {
  color: $color-neutral-cool-900;
  fill: $color-neutral-cool-500;
  &:hover {
    color: $color-neutral-black;
    fill: $color-neutral-cool-600;
  }
}
.search-svg-container {
  height: 24px;
  margin-right: 6px;
  width: 24px;
  &.clear-svg {
    height: 16px;
    width: 16px;
    svg {
      height: 16px;
      width: 16px;
    }
  }
}
.search-input {
  background: none;
  border: none;
  font-size: 1rem;
  line-height: 24px;
  outline: none;
  width: 100%;
  &::-webkit-search-cancel-button {
    display: none;
  }
}
button {
  background: none;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
</style>
